import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import API from "../../API";
import AddUser from "../partials/AddUser";
import { Button, Table } from "react-bootstrap";
import "./Tournament.css";

export default function Tournament(props) {
  const [tournament, setTournament] = useState({});
  const currentUser = props.currentUser;
  const [players, setPlayers] = useState([]);
  const [scores, setScores] = useState([]);
  const { id } = useParams();
  const [allUsers, setAllUsers] = useState([]);
  const [addMode, setAddMode] = useState(false);
  const [userSearch, setUserSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    API.get(`api/tournaments/${id}`).then((response) => {
      console.log(response.data);
      setTournament(response.data);
      setPlayers(response.data.players);
    });
    API.get("api/players/").then((response) => {
      setAllPlayers(response.data);
      setRender(true);
    });
    API.get(`tournament/${id}/round-scores`).then((response) => {
      setScores(response.data);
    });
  }, []);

  const userCheck = () => {
    if (playerIdArray.includes(currentUser)) {
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (currentUser) {
      userCheck();
      console.log(currentUser);
      console.log(playerIdArray);
    }
  }, [players]);

  useEffect(() => {}, [userSearch]);

  const datify = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const dateFormatted = date.toLocaleDateString();
      return dateFormatted;
    }
  };

  let date1 = datify(tournament?.date1);
  let date2 = datify(tournament?.date2);
  let date3 = datify(tournament?.date3);
  let date4 = datify(tournament?.date4);

  function parFormatter(par) {
    if (par === 0) {
      return "E";
    } else if (par > 0) {
      return `+${par}`;
    } else {
      return `${par}`;
    }
  }

  function roundScoreFormatter({
    holes_played,
    over_under_par,
    total_par,
    total_score,
  }) {
    if (holes_played === 0) {
      return "-";
    } else if (holes_played === 18) {
      return `${total_score} (${parFormatter(over_under_par)})`;
    } else {
      return `${parFormatter(over_under_par)} (thru ${holes_played})`;
    }
  }

  const scoresMap = scores.map((player) => {
    console.log(player);
    return (
      <tr key={player.user.id}>
        {player.user.first_name === "" ? (
          <td>{player.user.username}</td>
        ) : (
          <td>
            {player.user.first_name} {player.user.last_name}
          </td>
        )}
        {date1 ? (
          <td>
            {player.round_scores[1]
              ? roundScoreFormatter(player.round_scores[1])
              : "-"}
          </td>
        ) : null}
        {date2 ? (
          <td>
            {player.round_scores[2]
              ? roundScoreFormatter(player.round_scores[2])
              : "-"}
          </td>
        ) : null}
        {date3 ? (
          <td>
            {player.round_scores[3]
              ? roundScoreFormatter(player.round_scores[3])
              : "-"}
          </td>
        ) : null}
        {date4 ? (
          <td>
            {player.round_scores[4]
              ? roundScoreFormatter(player.round_scores[4])
              : "-"}
          </td>
        ) : null}
        <td>{parFormatter(player.over_under_par)}</td>
      </tr>
    );
  });

  const handleAddMode = () => {
    if (!addMode) {
      const getAllUsers = async () => {
        const findUsers = await API.get("/api/users/");
        setAllUsers(findUsers.data);
      };
      getAllUsers();
      setAddMode(true);
    } else {
      setAddMode(false);
    }
  };

  let playerIdArray = players.map((player) => {
    return player.id;
  });

  const filterUsers = allUsers.filter((user) => {
    return user.username.toLowerCase().includes(userSearch.toLowerCase());
  });

  const allPlayersFilter = allPlayers.filter((player) => {
    return player.tournament == tournament.id;
  });

  return (
    <div className="tournamentDiv">
      <img
        src="/tournament_banner.jpg"
        className="tournamentBanner"
        alt="golf tee box with stands behind the fairway"
      />
      <div className="tournamentHeaderDiv">
        <h1 className="tournamentName">{tournament.name}</h1>
        <h6>Round1</h6>
        <p>
          {date1}
          {tournament.course1?.name ? ` - ${tournament.course1.name}` : ""}
        </p>
        {date2 ? (
          <>
            <h6>Round2</h6>
            <p>
              {date2}
              {tournament.course2.name ? ` - ${tournament.course2.name}` : ""}
            </p>
          </>
        ) : null}

        {date3 ? (
          <>
            <h6>Round3</h6>
            <p>
              {date3}
              {tournament.course3.name ? ` - ${tournament.course3.name}` : ""}
            </p>{" "}
          </>
        ) : null}
        {date4 ? (
          <>
            <h6>Round3</h6>
            <p>
              {date4}
              {tournament.course4.name ? ` - ${tournament.course4.name}` : ""}
            </p>
          </>
        ) : null}
      </div>
      <div className="tournamentButtons">
        {/* <Link to={`/tournament/${tournament.id}/leaderboard`}><Button variant="success">Live Leaderboard</Button></Link> */}
        {isPlaying ? (
          <div className="dropdown">
            <Button
              className="btn btn-success dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              My Scorecard
            </Button>
            <ul className="dropdown-menu">
              <li>
                <Link
                  to={`/tournament/scorecard/${tournament.id}/1`}
                  className="dropdown-item"
                  href="#"
                >
                  Round 1
                </Link>
              </li>
              {date2 ? (
                <li>
                  <Link
                    to={`/tournament/scorecard/${tournament.id}/2`}
                    className="dropdown-item"
                    href="#"
                  >
                    Round 2
                  </Link>
                </li>
              ) : null}
              {date3 ? (
                <li>
                  <Link
                    to={`/tournament/scorecard/${tournament.id}/3`}
                    className="dropdown-item"
                    href="#"
                  >
                    Round 3
                  </Link>
                </li>
              ) : null}
              {date4 ? (
                <li>
                  <Link
                    to={`/tournament/scorecard/${tournament.id}/1`}
                    className="dropdown-item"
                    href="#"
                  >
                    Round 4
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
        ) : null}

        {tournament.organizer == currentUser ? (
          <Button variant="success" onClick={handleAddMode}>
            Add/Remove Players
          </Button>
        ) : null}
      </div>
      {addMode ? (
        <>
          <input
            type="text"
            id="userSearch"
            placeholder="search by username..."
            value={userSearch}
            onChange={(e) => setUserSearch(e.target.value)}
            className="searchbar"
          />
          {/* <ul>
                        {filterUsers.map((user) => {
                            return <AddUser user={user} tournament={tournament.id} playerIdArray={playerIdArray} players={players} allPlayersFilter={allPlayersFilter}/>
                        })}
                    </ul>     */}
        </>
      ) : null}
      <div className="leaderboardDiv">
        <h2>Leaderboard</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Player</th>
              {date1 ? <th>R1</th> : null}
              {date2 ? <th>R2</th> : null}
              {date3 ? <th>R3</th> : null}
              {date4 ? <th>R4</th> : null}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>{scoresMap}</tbody>
        </Table>
      </div>
    </div>
  );
}
