import { useParams, Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import API from "../../API";
import "./Scorecard.css";
import {
  ArrowLeft,
  ArrowClockwise,
  ChevronCompactLeft,
  ChevronCompactRight,
} from "react-bootstrap-icons";
import { Badge, Button, Dropdown, DropdownButton, Form } from "react-bootstrap";

export default function Scorecard(props) {
  const currentUser = props.currentUser;
  const { tournamentId } = useParams();
  const { round } = useParams();
  const [currentPartner, setCurrentPartner] = useState(0);
  const [scorecard, setScorecard] = useState({});
  const [currentHole, setCurrentHole] = useState(0);
  const [frontScore, setFrontScore] = useState(0);
  const [backScore, setBackScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [currentHoleScore, setCurrentHoleScore] = useState(0);
  const navigate = useNavigate();

  const findCurrentHole = (scorecard) => {
    let i = 1;
    while (i <= 18) {
      if (scorecard[i].score === 0) {
        setCurrentHoleScore(scorecard[i].score);
        return i;
      } else if (i === 18) {
        setCurrentHoleScore(scorecard[i].score);
        return i;
      } else {
        i++;
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      API.get(
        `user/${currentUser}/tournament/${tournamentId}/round/${round}/scorecard/`
      ).then((response) => {
        setScorecard(response.data);
        setCurrentHole(findCurrentHole(response.data.holes));
        setFrontScore(response.data.front_score);
        setBackScore(response.data.back_score);
        setTotalScore(response.data.total_score);
      });
    }
    console.log("currentUser", currentUser);
  }, [currentUser]);

  //   useEffect(() => {
  //     console.log("currentHole", currentHole);
  //   }, [currentHole]);
  useEffect(() => {
    if (currentHole > 0)
      setCurrentPartner(scorecard.holes[currentHole].partner ?? null);
    console.log(currentPartner);
  }, [currentHole]);

  useEffect(() => {
    console.log("scorecard", scorecard);
  }, [scorecard]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const scoreDetails = {
      user: currentUser,
      date: scorecard.date,
      tournament: tournamentId,
      course: scorecard.course.id,
      round_number: round,
      hole: scorecard.holes[currentHole].hole_id,
      score: currentHoleScore,
      partner: currentPartner ? currentPartner.id : null,
      beers_drank: 1,
      glizzies_consumed: 0,
    };
    await API.post(`/api/scoreupdate/`, scoreDetails).then(() => {});
  };

  const handleSubmitAndExit = (e) => {
    e.preventDefault();
    handleSubmit(e);
    navigate(`/tournament/${tournamentId}`);
  };

  const handleIncreasePress = (e) => {
    e.preventDefault();
    setCurrentHoleScore(currentHoleScore + 1);
    if (currentHole <= 9) {
      setFrontScore(frontScore + 1);
    } else {
      setBackScore(backScore + 1);
    }
    setTotalScore(totalScore + 1);
    setScorecard({
      ...scorecard,
      holes: {
        ...scorecard.holes,
        [currentHole]: {
          ...scorecard.holes[currentHole],
          score: currentHoleScore + 1,
        },
      },
    });
  };

  const handleDecreasePress = (e) => {
    e.preventDefault();
    if (currentHoleScore > 0) {
      setCurrentHoleScore(currentHoleScore - 1);
      if (currentHole <= 9) {
        setFrontScore(frontScore - 1);
      } else {
        setBackScore(backScore - 1);
      }
      setTotalScore(totalScore - 1);
      setScorecard({
        ...scorecard,
        holes: {
          ...scorecard.holes,
          [currentHole]: {
            ...scorecard.holes[currentHole],
            score: currentHoleScore - 1,
          },
        },
      });
    }
  };

  const handleNextHole = (e) => {
    if (currentHole < 18) {
      e.preventDefault();
      handleSubmit(e);
      setCurrentHole(currentHole + 1);
      setCurrentHoleScore(scorecard.holes[currentHole + 1].score);
    }
  };

  const handlePreviousHole = (e) => {
    if (currentHole > 1) {
      e.preventDefault();
      handleSubmit(e);
      setCurrentHole(currentHole - 1);
      setCurrentHoleScore(scorecard.holes[currentHole - 1].score);
    }
  };

  const handleSelectHole = (e, hole) => {
    e.preventDefault();
    setCurrentHole(hole);
    setCurrentHoleScore(scorecard.holes[hole].score);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const holeDisplayMap = (holes, i) => {
    return holes.map((hole) => {
      return (
        <div
          className={
            hole.hole_number === currentHole ? "holeDiv activeHole" : "holeDiv"
          }
          key={`hole${hole.hole_number}`}
          onClick={(e) => {
            handleSelectHole(e, hole.hole_number);
          }}
        >
          <label htmlFor={`hole{hole.hole_number}`}>{hole.hole_number}</label>
          <Form.Control
            type="number"
            id={`hole${hole.hole_number}`}
            value={hole.score}
            className="scoreInput"
            readOnly
          />
          <div className="score"> {hole.score} </div>
        </div>
      );
    });
  };

  const handlePartnerSelect = (e, partner) => {
    e.preventDefault();
    console.log(e.target.value);
    for (let i = 1; i <= 4; i++) {
      if (scorecard.grouping[`user${i}`].id === parseInt(e.target.value)) {
        setCurrentPartner(scorecard.grouping[`user${i}`]);
        setScorecard({
          ...scorecard,
          holes: {
            ...scorecard.holes,
            [currentHole]: {
              ...scorecard.holes[currentHole],
              partner: scorecard.grouping[`user${i}`],
            },
          },
        });
      }
    }
  };

  if (scorecard.holes) {
    return (
      <div className="scorecardDiv">
        {tournamentId ? (
          <span className="topButtons">
            <p className="back">
              <Link to={`/tournament/${tournamentId}/`} className="back">
                <ArrowLeft />
                back
              </Link>
            </p>
            <p className="back">
              <Link className="back">
                <ArrowClockwise
                  className="refresh"
                  onClick={(e) => handleRefresh(e)}
                />
                Refresh
              </Link>
            </p>
          </span>
        ) : null}
        <span className="scorecardHeader">
          <h1>Scorecard</h1>
        </span>
        <h2 className="nineheader">Front Nine</h2>
        <div className="frontNine nine">
          {holeDisplayMap([
            scorecard.holes[1],
            scorecard.holes[2],
            scorecard.holes[3],
            scorecard.holes[4],
            scorecard.holes[5],
            scorecard.holes[6],
            scorecard.holes[7],
            scorecard.holes[8],
            scorecard.holes[9],
          ])}
          <div className="hole totalDiv">
            <label htmlFor="total">Front</label>
            <div className="total score"> {frontScore} </div>
          </div>
        </div>
        <h2 className="nineheader">Back Nine</h2>
        <div className="backNine nine">
          {holeDisplayMap([
            scorecard.holes[10],
            scorecard.holes[11],
            scorecard.holes[12],
            scorecard.holes[13],
            scorecard.holes[14],
            scorecard.holes[15],
            scorecard.holes[16],
            scorecard.holes[17],
            scorecard.holes[18],
          ])}
          <div className="hole totalDiv">
            <label htmlFor="total">Back</label>
            <div className="total score"> {backScore} </div>
          </div>
        </div>
        <div className="partnerDiv">
          Partner:
          <Form.Select
            size="sm"
            id="partnerChoose"
            onChange={(e) => handlePartnerSelect(e)}
            value={currentPartner ? currentPartner.id : 0}
          >
            <option value={0}>Select Partner</option>
            {scorecard.grouping.user1.id !== currentUser &&
            scorecard.grouping.user1 !== null ? (
              <option value={scorecard.grouping.user1.id}>
                {scorecard.grouping.user1.first_name
                  ? `${scorecard.grouping.user1.first_name} ${scorecard.grouping.user1.last_name}`
                  : scorecard.grouping.user1.username}
              </option>
            ) : (
              ""
            )}
            {scorecard.grouping.user2.id !== currentUser &&
            scorecard.grouping.user2 !== null ? (
              <option value={scorecard.grouping.user2.id}>
                {scorecard.grouping.user2.first_name
                  ? `${scorecard.grouping.user2.first_name} ${scorecard.grouping.user2.last_name}`
                  : scorecard.grouping.user2.username}
              </option>
            ) : (
              ""
            )}
            {scorecard.grouping.user3.id !== currentUser &&
            scorecard.grouping.user3 !== null ? (
              <option value={scorecard.grouping.user3.id}>
                {scorecard.grouping.user3.first_name
                  ? `${scorecard.grouping.user3.first_name} ${scorecard.grouping.user3.last_name}`
                  : scorecard.grouping.user3.username}
              </option>
            ) : (
              ""
            )}
            {scorecard.grouping.user4.id !== currentUser &&
            scorecard.grouping.user4 !== null ? (
              <option value={scorecard.grouping.user4.id}>
                {scorecard.grouping.user4.first_name
                  ? `${scorecard.grouping.user4.first_name} ${scorecard.grouping.user4.last_name}`
                  : scorecard.grouping.user4.username}
              </option>
            ) : (
              ""
            )}
          </Form.Select>
        </div>
        <div>
          <div className="holeNumberDiv">
            <h3 className="holeHeader">Hole &nbsp;</h3>
            <h1 className="holeNumber">{currentHole}</h1>
            <div className="totalScoresDiv">
              <div className="totalScoreHeaders">
                <h5 className="totalScore">Total</h5>
              </div>
              <div className="totalScoreNumbers">
                <h5 className="totalScore">{totalScore}</h5>
              </div>
            </div>
          </div>
          <div className="currentHoleDiv">
            <Button
              className={
                currentHole <= 1 ? "holeButton inactiveButton" : "holeButton"
              }
              onClick={handlePreviousHole}
              variant="success"
            >
              <ChevronCompactLeft className="chevron" /> <p>Previous Hole</p>
            </Button>
            <div className="scoreDiv">
              <p className="scoreWordDiv">Score</p>
              <div className="currentHoleScore">{currentHoleScore} </div>
            </div>
            <div className="scoreButtonDiv">
              <Button
                className="scoreButton"
                onClick={handleIncreasePress}
                variant="success"
              >
                +
              </Button>
              <Button
                className="scoreButton"
                onClick={handleDecreasePress}
                variant="success"
              >
                -
              </Button>
            </div>
            <Button
              className={
                currentHole === 18 ? "holeButton inactiveButton" : "holeButton"
              }
              onClick={handleNextHole}
              variant="success"
            >
              <ChevronCompactRight className="chevron" /> <p>Next Hole</p>
            </Button>
          </div>
        </div>
        <div className="submitButtonsDiv">
          <div className="buttonDiv">
            <Button
              type="submit"
              variant="success"
              onClick={handleSubmitAndExit}
              className="submitButton"
            >
              Submit & Exit
            </Button>
          </div>
          <div className="buttonDiv">
            <Button
              type="submit"
              variant="success"
              onClick={handleSubmit}
              className="submitButton"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}
